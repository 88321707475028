import styled from 'styled-components';

import * as text from '@/components/general/TextStyles';

interface IBox {
  $reverse: boolean;
}

export const Box = styled.div<IBox>`
  width: 100%;
  display: flex;
  flex-direction: ${(props: IBox) => (props.$reverse ? 'row-reverse' : 'row')};
  justify-content: space-between;
  align-items: center;
  column-gap: 40px;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    flex-direction: column-reverse;
    row-gap: 30px;
    align-items: center;
    margin: 0;
  }
`;
export const TextBox = styled.div``;

interface ITitle {
  $maxWidth?: number;
}

export const Title = styled.h2<ITitle>`
  ${text.FS42_LG}
  margin-bottom: 20px;
  max-width: ${(props: ITitle) =>
    props.$maxWidth ? `${props.$maxWidth}px` : '542px'};

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    max-width: 600px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    margin-bottom: 15px;
    max-width: none;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.xs}px) {
    font-size: 20px;
    margin-bottom: 10px;
  }
`;

export const Description = styled.p`
  ${text.FS17}
  max-width: 538px;
  color: ${(props) => props.theme.colors.black};

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    max-width: none;
    color: ${(props) => props.theme.colors.grayText};
  }
`;

export const ImageContainer = styled.div`
  min-height: 430px;
  display: flex;
  align-items: center;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    min-height: auto;
  }
`;

export const ImageBox = styled.div`
  width: 430px;
  min-width: 430px;
  display: flex;
  justify-content: center;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    min-width: 0;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    width: ${(props) => `calc(430 / ${props.theme.breakpoints.md} * 100vw)`};
  }
  @media (max-width: ${(props) => props.theme.breakpoints.sm}px) {
    width: ${(props) => `calc(250 / ${props.theme.breakpoints.xs} * 100vw)`};
  }
`;

interface IImage {
  height?: number;
  width?: number;
  $aspectHeight?: number;
}

export const Image = styled.img<IImage>`
  border-radius: 20px;
  width: ${(props: IImage) =>
    props.width ? `calc(${props.width}/430 * 100%)` : '430px'};
  ${(props) =>
    props.$aspectHeight
      ? `aspect-ratio: 1/${props.$aspectHeight};`
      : props.height
      ? `height: ${props.height}px;`
      : 'height: 100%;'}

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    width: ${(props: IImage) =>
      props.width ? `calc(${props.width}/430 * 100%)` : '100%'};
    ${(props) => !props.$aspectHeight && 'height: 100%;'}
  }
`;
