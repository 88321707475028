import React from 'react';
import reactStringReplace from 'react-string-replace';

import { BorderLink } from '@/components/general';
import * as styled from './Card.styled';
import { ICard } from '../../ProductsInfo';

interface ICardExtended {
  card: ICard;
  $reverse: boolean;
}

export const Card: React.FC<ICardExtended> = ({ card, $reverse }) => {
  const {
    title,
    titleMaxWidth,
    description,
    image,
    imageHeight,
    aspectHeight,
    imageWidth,
    linkReg,
    links,
  } = card;

  return (
    <styled.Box $reverse={$reverse}>
      <styled.TextBox>
        <styled.Title $maxWidth={titleMaxWidth}>{title}</styled.Title>
        <styled.Description>
          {reactStringReplace(description, linkReg, (match, i) => {
            const index = Math.floor(i / 2);
            return (
              <BorderLink key={links[index]} to={links[index]}>
                {match}
              </BorderLink>
            );
          })}
        </styled.Description>
      </styled.TextBox>
      {image && (
        <styled.ImageContainer>
          <styled.ImageBox>
            <styled.Image
              src={image}
              height={imageHeight}
              width={imageWidth}
              $aspectHeight={aspectHeight}
            />
          </styled.ImageBox>
        </styled.ImageContainer>
      )}
    </styled.Box>
  );
};
