import styled from 'styled-components';

import { StyledButton, StyledLink } from '@/components/general';
import { BlurBox as generalBlurBox } from '../general/FormPages.styled';

export const BlurBox = styled(generalBlurBox)`
  width: 129vw;
  border-radius: 129vw;
  height: 705px;
  top: 370px;
  left: -15vw;
  min-height: 0px;
  max-height: none;
  opacity: 1;

  @media (max-width: ${(props) => props.theme.breakpoints.xl}px) {
    height: auto;
    aspect-ratio: 1/0.49;
  }
`;

export const TopBoxDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 55px;

  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    margin-bottom: 45px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    max-width: 470px;
    margin: 0 auto 50px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.sm}px) {
    max-width: none;
  }
`;

interface IButton {
  $hasPadding?: boolean;
}

export const Button = styled(StyledButton)<IButton>`
  padding: ${(props: IButton) => (props.$hasPadding ? '0 25px' : '0')};
  height: 50px;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}px) {
    padding: ${(props: IButton) => (props.$hasPadding ? '0 15px' : '0')};
    height: 45px;
  }
`;

export const ButtonLink = styled(StyledLink)`
  padding: 0 25px;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}px) {
    padding: 0 15px;
  }
`;

export const ButtonBox = styled.div`
  margin-top: 25px;
  display: flex;
  column-gap: 20px;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}px) {
    margin-top: 30px;
    justify-content: center;
    column-gap: 15px;
    width: 100%;
  }

  & a,
  & button {
    border-radius: 16px;
    width: 180px;
    height: 56px;
    font-size: 17px;
    text-align: center;

    @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
      font-size: 15px;
      height: 50px;
      width: 167px;
    }
    @media (max-width: ${(props) => props.theme.breakpoints.sm}px) {
      width: 100%;
    }
  }
`;

export const Main = styled.main`
  width: 100%;
  max-width: 1105px;
  margin: 150px auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    margin: 100px auto 0;
  }
`;

export const ImageBox = styled.div`
  width: 100%;
  border-radius: 16px;
  border: 6px solid rgba(255, 255, 255, 0.8);
  max-width: 1117px;
  aspect-ratio: 1/0.629;
  margin: 0 auto;
  overflow: hidden;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}px) {
    border-radius: 5px;
    border-width: 4px;
  }
`;

export const Image = styled.img`
  background-color: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;
`;

export const CardsBox = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 150px;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    row-gap: 80px;
  }
`;
