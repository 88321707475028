import React from 'react';

import { ThemeProvider } from '@/themes/theme';
import { GlobalStyle } from '@/themes/globalStyle';
import Wrapper from '@/components/Wrapper';
import { Header } from '@/components/Header';
import { Content } from '@/components/Products';
import { Footer } from '@/components/Footer';
import { removeSpaces } from '@/utils/functions';
import { Meta } from '@/components/general/Meta';
import { IProduct, PRODUCTS } from '@/components/Products/ProductsInfo';

const Products = ({ pageContext }) => {
  let currentProduct: IProduct;
  if (pageContext.page === 'templates/about') {
    currentProduct = PRODUCTS.find(
      (product) => product.title === 'App Templates',
    );
  } else if (pageContext.page === 'ai') {
    currentProduct = PRODUCTS.find(
      (product) => product.title === 'AI App Builder',
    );
  } else {
    currentProduct = PRODUCTS.find(
      (product) => removeSpaces(product.title) === pageContext.page,
    );
  }

  return (
    <ThemeProvider>
      <Meta
        title={`${currentProduct.title} | CodePlatform`}
        description={currentProduct.description}
        url={`/${pageContext.page}`}
      />
      <GlobalStyle />
      <Wrapper>
        <Header currentpath={currentProduct.title} />
        <Content productName={pageContext.page} />
        <Footer />
      </Wrapper>
    </ThemeProvider>
  );
};

export default Products;
