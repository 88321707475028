import React from 'react';

import {
  Layout,
  BlueBox,
  Button,
  ButtonColors,
  Breadcrumbs,
  BlurBox,
} from '@/components/general';
import { removeSpaces } from '@/utils/functions';
import { WhatSection } from '@/components/Blog/BlogArticle/components/WhatSection';
import { Links } from '@/utils/constants';
import { Title, UnderTitle } from '../general/FormPages.styled';
import * as styled from './Content.styled';
import { IProduct, PRODUCTS } from './ProductsInfo';
import { Card } from './components';
import { AI } from '../AI';

interface IContent {
  productName: string;
}

export const Content: React.FC<IContent> = ({ productName }) => {
  let product: IProduct;
  let links: (Links | string)[];

  if (productName === 'templates/about') {
    const templatesProduct = PRODUCTS.find(
      (_product) => _product.title === 'App Templates',
    );
    const templatesProductCopy: IProduct = JSON.parse(
      JSON.stringify(templatesProduct),
    );
    product = {
      ...templatesProductCopy,
      cards: templatesProduct.cards,
      title: 'About App Templates',
    };
    links = [Links.TEMPLATES, 'About'];
  } else if (productName === 'ai') {
    product = PRODUCTS.find((_product) => _product.title === 'AI App Builder');
    links = ['AI'];
  } else {
    product = PRODUCTS.find(
      (_product) => removeSpaces(_product.title) === productName,
    );
    links = [product?.title];
  }

  const scrollToProducts = () => {
    const id = 'all-products';
    const yOffset = -100;
    const element = document.getElementById(id);
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y, behavior: 'smooth' });
  };

  return (
    <Layout>
      <Breadcrumbs links={links} />
      <BlurBox blurComponent={<styled.BlurBox />}>
        <styled.TopBoxDescription>
          <Title>{product?.title}</Title>
          <UnderTitle style={{ maxWidth: '430px' }}>
            {product?.description}
          </UnderTitle>
          <styled.ButtonBox>
            <AI intent="start-building">
              <Button>Start building</Button>
            </AI>
            <Button
              onClick={() => scrollToProducts()}
              color={ButtonColors.SECONDARY}
            >
              All features
            </Button>
          </styled.ButtonBox>
        </styled.TopBoxDescription>
        <styled.ImageBox>
          <styled.Image src={product?.image} alt={product?.title} />
        </styled.ImageBox>
      </BlurBox>
      <styled.Main>
        <styled.CardsBox>
          {product?.cards?.map((card, index) => (
            <Card key={card.title} card={card} $reverse={!!(index % 2)} />
          ))}
        </styled.CardsBox>
        <WhatSection />
        <BlueBox
          title="What's next?"
          description="Start building great apps today"
        />
      </styled.Main>
    </Layout>
  );
};
